<script>
export default {
  props: {
    padding: {
      type: String,
      required: false,
      allowVariable: false,
      default: "10",
      valid: [
        "None",
        "5",
        "10",
        "15",
        "20",
        "25",
        "30",
        "35",
        "40",
        "45",
        "50",
        "55",
        "60",
        "65",
        "70",
        "75",
        "80",
        "85",
        "90",
        "95",
        "100"
      ],
      description: "Padding in pixels"
    }
  }
};
</script>
<template>
  <div>
    <template v-if="padding !== 'None'">
      <div :style="'padding-bottom: ' + padding + 'px'" />
    </template>
  </div>
</template>
